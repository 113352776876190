<template>
  <div class="book-shelf-guide-wrapper">
    <div class="guide-img-wrapper">
      <!-- <img class="guide-img" :src="logo" /> -->
      <div class="guide-img" :style="{'background-image': `url(${require('@/assets/images/logo.png')})`}"></div>
    </div>
    <div class="guide-text-wrapper">
      <!-- <div class="guide-text animate__animated animate__bounceInRight" v-html="$t('shelf.welcome')"></div> -->
      <!-- <div class="guide-text animate__animated animate__bounceInLeft" v-html="$t('shelf.welcome')"></div> -->
      <!-- <div class="guide-text" v-html="$t('shelf.welcome')"></div> -->
    </div>
    <div class="guide-btn-wrapper">
      <div class="guide-btn" @click="gotoStudy">{{$t('shelf.toAdmin')}}</div>
      <div class="guide-btn" @click="gotoBookStore">{{$t('shelf.find')}}</div>
    </div>
  </div>
</template>
<script>

  import 'animate.css'
  export default {
    data() {
      return {
        img: require('@/assets/images/guidePage.jpg'),
        logo: require('@/assets/images/logo.png')
      }
    },
    methods: {
      gotoBookStore() {
        this.$router.push('/store/shelf')
      },
      gotoStudy() {
        window.location.href = 'https://admin.kenym.cn'
      }
    }
  }
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
  @import "../../assets/styles/global";
  .book-shelf-guide-wrapper {
    background-color: #fff;
    width: 100%;
    height: 100%;
    .guide-img-wrapper {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      text-align: center;
      z-index: 999;
      .guide-img {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100%;
        background-attachment: fixed;
        background-position: 50% 18%;
      }
    }
    .guide-text-wrapper {
      position: fixed;
      bottom: px2rem(80);
      width: 100%;
      /* margin-top: px2rem(30); */
      text-align: center;
      /* padding: 0 px2rem(15); */
      box-sizing: border-box;
      .guide-text {
        font-size: px2rem(16);
        color: #333;
        line-height: px2rem(30);
      }
    }
    .guide-btn-wrapper {
      position: fixed;
      bottom: px2rem(10);
      width: 100%;
      /* margin-top: px2rem(50); */
      padding: px2rem(30) px2rem(15) px2rem(15) px2rem(15);
      box-sizing: border-box;
      @include center;
      .guide-btn {
        width: 100%;
        font-size: px2rem(14);
        font-weight: bold;
        color: white;
        padding: px2rem(15) 0;
        text-align: center;
        border-radius: px2rem(10);
        background: $color-blue;
        &:first-child {
          margin-right: px2rem(7.5);
          background: $color-blue;
        }
        &:last-child {
          margin-left: px2rem(7.5);
        }
        &:active {
          background: $color-blue-transparent;
        }
      }
    }
  }
</style>
